import React, { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"

import design from "../../../../../images/mobile/solutions/corporate/next/design.svg"
import gameficacao from "../../../../../images/mobile/solutions/corporate/next/gameficacao.svg"

import "./SectionFour.scss"

export default function SectionFour() {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
      easing: "ease-in-out",
      offset: 50,
    })
  }, [])

  return (
    <div id="next-section-four">
      <div id="content">
        <h1 className="title" data-aos="fade-up">
          Ferramentas do programa
        </h1>
        <div className="section-four-grid">
          <div className="section-four-image">
            <img data-aos="rotate-cw" src={design} alt="CRISP" />
          </div>
          <div className="section-four-text">
            <h1 data-aos="fade-right">Design Thinking</h1>
            <p data-aos="fade-right" data-aos-delay="150">
              Utilizamos as etapas do <b>Design Thinking</b> como um guia no
              processo de e evolução das ideias de inovação dos participantes.
              Empoderamos cada integrante para dominar essas práticas e
              replicá-las em outros contextos, disseminando o{" "}
              <b>conhecimento de forma aplicada</b>.
            </p>
          </div>
        </div>

        <div className="section-four-grid-two">
          <div className="section-four-image">
            <img data-aos="rotate-cw" src={gameficacao} alt="CRISP" />
          </div>
          <div className="section-four-text">
            <h1 data-aos="fade-right">Ciclo da Gameficação</h1>
            <p data-aos="fade-right" data-aos-delay="100">
              Após cada aula ministrada, os participantes receberão uma
              atividade para avançarem com seus{" "}
              <b>projetos de ciência de dados</b>e serão recompensados com
              pontos, inserindo <b>gamificação ao programa</b>.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
