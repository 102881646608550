import React, { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"

import seta from "../../../../../images/mobile/solutions/arrow-desk.svg"
import next from "../../../../../images/mobile/solutions/corporate/next.svg"

import "./SectionOne.scss"

const SectionOne = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
      easing: "ease-in-out",
      offset: 50,
    })
  }, [])

  return (
    <div id="next-section-one">
      <div id="content">
        <div className="section-one-text">
          <h1 className="title" data-aos="fade-right">
            NEXT
          </h1>
          <p data-aos="fade-right">
          Formamos profissionais capazes de liderar e influenciar o 
          processo de transformação digital nos setores que atuam.
          </p>
        </div>
        <div className="section-one-image">
          <img src={next} alt="Missão" data-aos="fade-left" />
          <div className="section-one-member">
            <img src={seta} alt="arrow" className="arrow bounce-infinite" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionOne
